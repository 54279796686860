import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Drawer, Space, Spin, Table, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Role } from '../../utils/types/role';
import { ColDiv, EditViewIcon } from '../common/styles';
import { formattedDate } from '../common/utils';
import PostForm from '../FormComponent/PostForm';

export interface PostTableComponentProps {
  className: string;
  postData: Post[];
  onlyDisplayActiveResources?: boolean;
  onTableChange: (pagination: TablePaginationConfig) => void;
  pagination: TablePaginationConfig;
  currentLoginUser: any;
}
const PostTableComponent: React.FC<PostTableComponentProps> = (props: PostTableComponentProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isloading, setIsloading] = useState<boolean>(false);
  const [permission, setPermission] = useState<'view' | 'edit' | 'create'>('view');
  const [currentPostId, setCurrentPostId] = useState<string>('');

  const {
    postData,
    className,
    onlyDisplayActiveResources,
    onTableChange,
    pagination,
    currentLoginUser
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const postIdParam = queryParams.get('postId') ?? '';
    if (!postIdParam) return;

    setCurrentPostId(postIdParam);
    onEdit();
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const onView = () => {
    setPermission('view');
    showDrawer();
  };

  const onEdit = () => {
    setPermission('edit');
    showDrawer();
  };

  const columns = [
    {
      title: 'Post',
      dataIndex: 'post',
      key: 'post',
      render: (text, record) => <ColDiv>{record?.post ? record.post.slice(0, 200) : null}</ColDiv>,
      width: 300
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
      render: (text, record) => (
        <ColDiv>{record?.response[0]?.body ? record.response[0].body.slice(0, 200) : null}</ColDiv>
      ),
      width: 300
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <ColDiv>{record?.response[0]?.status}</ColDiv>,
      width: 200
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => <ColDiv>{formattedDate(record?.createdAt)}</ColDiv>,
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <span
              onClick={() => {
                setCurrentPostId(record?._id);
                onView();
              }}
            >
              <EyeOutlined key="eye" />
            </span>
            {currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin ? (
              <span
                onClick={() => {
                  setCurrentPostId(record?._id);
                  onEdit();
                }}
              >
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <Spin spinning={isloading}>
      <Table
        className={`${className} ${onlyDisplayActiveResources === true ? 'hide' : ''}`}
        columns={columns}
        dataSource={postData}
        rowKey="_id"
        rowClassName={(record) => (record.isDeleted ? 'text-gray-400' : '')}
        onChange={onTableChange}
        pagination={pagination}
      />
      <Drawer
        title={`${permission === 'edit' ? 'Edit' : 'View'} Post Form`}
        placement="right"
        onClose={closeDrawer}
        visible={open}
        width="520"
        maskClosable={false}
        className="user-drawer"
        destroyOnClose
      >
        <PostForm
          currentPostId={currentPostId}
          permission={permission}
          onClose={closeDrawer}
          onSuccess={() => onTableChange(pagination)}
        />
      </Drawer>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.userMemberData.currentLoginUser
});
export default connect(mapStateToProps)(PostTableComponent);
