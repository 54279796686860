// export const API_URL = 'http://localhost:3000/';

import {
  FormOutlined,
  HomeOutlined,
  MessageOutlined,
  ReadOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserAddOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { TablePaginationConfig } from 'antd';
import { PaginationLocale } from 'antd/lib/pagination/Pagination';
import moment, { Moment } from 'moment';
import FilesIcon from './images/icons/files.svg';
import FormsIcon from './images/icons/forms.svg';
import FormsWhite from './images/icons/formsWhite.svg';
import LearningIcon from './images/icons/learning.svg';
import LearningWhite from './images/icons/learningWhite.svg';
import SessionIcon from './images/icons/MyProgramBlack.svg';
import SessionWhite from './images/icons/Myprograms_white.svg';
import DashboardIcon from './images/icons/newDashboardBlack.svg';
import ProgramIcon from './images/icons/programs.svg';
import ResourceIcon from './images/icons/resources.svg';
import ResourceWhite from './images/icons/resourceWhite.svg';
import WhiteUserIcon from './images/icons/userWhite.svg';
import UserIcon from './images/icons/user_black.svg';
import VideosIcon from './images/icons/videos.svg';
import WhiteDashboardIcon from './images/icons/whiteDashboardIcon.svg';
import SettingsWhiteIcon from './images/icons/whiteMyapp.svg';
import Whiteprograms from './images/icons/whiteprograms.svg';
import MemberIcon from './images/Member.svg';
import Memberwhite from './images/Memberwhite.svg';
import { Role } from './utils/types/role';

import SettingsIcon from './images/icons/myapp.svg';

const { API_PORT } = process.env;
export const API_URL = `${process.env.API_URL}${API_PORT ? `:${API_PORT}` : ''}/`;

export interface Country {
  name: string;
  code: string;
}

export interface TimeZoneProps {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}

export interface CountryCodeProps {
  name: string;
  dial_code: string;
  code: string;
}

export interface RoleTypeProps {
  label: string;
  value?: string;
}
export interface StatusProps {
  label: string;
  value?: string;
}

export interface LoginFormProps {
  email?: string;
  password?: string;
}

export interface ForgetPasswordProps {
  email?: string;
}

export interface ResetPasswordProps {
  password?: string;
  confirmPassword?: string;
}

export interface BasicForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: string;
  otherAddress?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  phoneCountryCode?: string;
  phoneNumber?: number;
  timeZone?: string;
  avatar?: string;
  type?: string;
  userStatus?: string;
  businessUserType?: RoleTypeProps[];
  choosePlan?: string;
  resourceAdmin?: boolean;
  uid?: string;
  csm?: string;
}

export interface GoalFormType {
  type?: string;
  member?: string;
  name?: string;
  // description?: string;
  isPrimary?: boolean;
  typeOfGoal?: string;
  complexity?: string;
  performanceEvaluation?: string;
  neededSkills?: Array<string>;
  membersBaseLineSkills?: string;
  challenges?: string;
  status?: string;
  comment?: string;
}

export interface MilestoneFormType {
  type?: Role;
  goal?: string;
  goalName?: string;
  name?: string;
  description?: string;
  targetSkills?: Array<string>;
  milestoneType?: any;
  needHelp?: boolean;
  notes?: string;
  comment?: string;
  teamMember?: Array<string>;
  status?: string;
}

export interface TaskFormType {
  commentBox?: any;
  name?: string;
  isCompleted?: boolean;
  description?: string;
  strategy?: string;
  taskType?: string;
  dueDate?: Moment | null | undefined | string;
  assignee?: Array<string>;
  contentLink?: Array<string>;
  fileLink?: Array<string>;
  milestone?: string;
  thridPartyAppName?: string;
  thridPartyAppDesc?: string;
  comment?: string;
  notes?: string;
  listData?: Array<any>;
}

export interface SessionNoteFormType {
  type?: string;
  member?: string;
  startTime?: string;
  endTime?: string;
  endtimeCancellation?: boolean;
  rescheduleOffered?: string;
  about?: string;
  title?: string;
  topWins?: Array<string>;
  challenges?: Array<string>;
  rateSession?: number;
  clientEngagement?: number;
  sessionRecorded?: boolean;
  recordingLink?: string;
  internalNotes?: string;
  isDeleted?: boolean;
  status?: string;
}

export interface MemberChildrenProps {
  members?: customerMemberType[];
}

export interface RouteListProps {
  sidebarTitle: string;
  icon?: any;
  activeIcon?: any;
  route?: string;
  key?: string;
  subKey?: string;
  sidebarList?: {
    label: string;
    permission: [Role.Admin | Role.Customer | Role.Business];
    route: string;
    icon: any;
    key?: string;
  }[];
}

export const RoleType: RoleTypeProps[] = [
  {
    label: 'Customer'
  },
  {
    label: 'Business'
  },
  {
    label: 'Administrator'
  }
];

export const UserStatus: RoleTypeProps[] = [
  {
    label: 'Active'
  },
  {
    label: 'Inactive'
  },
  {
    label: 'OnHold'
  }
];
export const GoalStatus: StatusProps[] = [
  {
    label: 'Achieved'
  },
  {
    label: 'In-progress'
  },
  {
    label: 'Planned'
  },
  {
    label: 'On-hold'
  },
  {
    label: 'Cancelled'
  }
];
export const VideoStatus: StatusProps[] = [
  {
    label: 'Draft'
  },
  {
    label: 'Published'
  },
  {
    label: 'Archived'
  }
];
export const skillStatus: StatusProps[] = [
  {
    label: 'Active'
  },
  {
    label: 'Mastered'
  },
  {
    label: 'Cancelled'
  },
  {
    label: 'On-hold'
  }
];
export const WorkShopStatus: RoleTypeProps[] = [
  {
    label: 'Accepted'
  },
  {
    label: 'Rejected'
  },
  {
    label: 'Pending'
  }
];

export const UserType: RoleTypeProps[] = [
  {
    label: 'Therapist'
  },
  {
    label: 'Mindset Coach'
  },
  {
    label: 'Customer Manager'
  },
  {
    label: 'Account Manager'
  },
  {
    label: 'Resource Manager'
  }
];

export const ChoosePlan: RoleTypeProps[] = [
  {
    label: 'Flagship'
  },
  {
    label: 'Subscriber'
  },
  {
    label: 'Choice'
  },
  {
    label: 'Care'
  },
  {
    label: 'Lite'
  },
  {
    label: 'Others'
  }
];

export const ListingType: any[] = [
  {
    label: 'Private',
    value: 'Private'
  },
  {
    label: 'AU NDIS',
    value: 'AU_NDIS'
  },
  {
    label: 'NHS',
    value: 'NHS'
  },
  {
    label: 'Medicare',
    value: 'Medicare'
  },
  {
    label: 'Others',
    value: 'Others'
  }
];

export const CustomerRouteList: RouteListProps[] = [
  {
    sidebarTitle: 'Dashboard',
    icon: DashboardIcon,
    activeIcon: WhiteDashboardIcon,
    route: ''
  },
  {
    sidebarTitle: 'Forms',
    icon: FormsIcon,
    activeIcon: FormsWhite,
    route: '/myforms'
  },
  // {
  //     sidebarTitle: "My Forms",
  //     icon: SolutionOutlined,
  //     sidebarList : [
  //         {
  //             "label": "Parent Onboarding",
  //             "permission": [Role.Customer],
  //             "route": "/parentOnboarding",
  //             "icon" : SolutionOutlined,
  //          },
  //          {
  //              "label": "child Onboarding",
  //              "permission": [Role.Customer],
  //              "route": "/childOnboarding",
  //              "icon" : UserOutlined,
  //          },
  //          {
  //              "label": "social skills assessment",
  //              "permission": [Role.Customer],
  //              "route": "/socialSkills",
  //              "icon" : UserAddOutlined,
  //          },
  //          {
  //              "label": "Sensory profile assessment",
  //              "permission": [Role.Customer],
  //              "route": "/sensoryProfile",
  //              "icon" : TeamOutlined,
  //          },
  //     ]
  // },
  {
    sidebarTitle: 'Program',
    icon: SessionIcon,
    activeIcon: SessionWhite,
    route: '/myprogram'
  },
  {
    sidebarTitle: 'Sessions',
    icon: ProgramIcon,
    activeIcon: Whiteprograms,
    route: '/mysessions'
  },
  // {
  //     sidebarTitle: "My Favorites",
  //     icon: FavouriteIcon,
  //     route: "/myfavorites",
  // },
  {
    sidebarTitle: 'Library',
    icon: LearningIcon,
    activeIcon: LearningWhite,
    route: '/mylearning'
  },
  {
    sidebarTitle: 'Resources',
    icon: ResourceIcon,
    activeIcon: ResourceWhite,
    route: '/myresources'
  },
  {
    sidebarTitle: 'My Videos',
    icon: VideosIcon,
    activeIcon: VideosIcon,
    route: '/sharedVideos'
  },
  {
    sidebarTitle: 'My Files',
    icon: FilesIcon,
    activeIcon: FilesIcon,
    route: '/clientFiles'
  }
  // {
  //     sidebarTitle: "My Apps",
  //     icon: MyAppIcon,
  //     route: "/myapps",
  // },
  // {
  //     sidebarTitle: "My Notifications",
  //     icon: NotificationIcon,
  //     route: "/mynotifications",
  // },
];

export const BussinessRouteList: RouteListProps[] = [
  {
    sidebarTitle: 'Customers',
    icon: UserIcon,
    subKey: '1',
    activeIcon: WhiteUserIcon,
    route: ''
  },
  {
    sidebarTitle: 'Members',
    icon: MemberIcon,
    subKey: '2',
    activeIcon: Memberwhite,
    route: '/users/members'
  },
  {
    sidebarTitle: 'CMS',
    icon: DashboardIcon,
    activeIcon: SettingsWhiteIcon,
    subKey: '3',
    sidebarList: [
      {
        label: 'Resources',
        icon: SolutionOutlined,
        key: '3',
        route: '/resources',
        permission: [Role.Business]
      },
      {
        label: 'Videos',
        key: '4',
        icon: VideoCameraAddOutlined,
        route: '/videos',
        permission: [Role.Business]
      },
      {
        label: 'Workshops',
        icon: VideoCameraOutlined,
        key: '5',
        route: '/workshops',
        permission: [Role.Business]
      },
      {
        label: 'Course',
        icon: VideoCameraAddOutlined,
        key: '6',
        route: '/course',
        permission: [Role.Business]
      },
      {
        label: 'Blogs',
        permission: [Role.Business],
        route: '/blogs',
        icon: ReadOutlined,
        key: '7'
      },
      {
        label: 'Messages',
        permission: [Role.Business],
        route: '/broadcastmessages',
        icon: MessageOutlined,
        key: '8'
      },
      {
        label: 'Posts',
        permission: [Role.Business],
        route: '/posts',
        icon: FormOutlined,
        key: '9'
      },
      {
        label: 'Home',
        permission: [Role.Business],
        route: '/home',
        icon: HomeOutlined,
        key: '10'
      }
    ]
  }
];

export const AdminRouteList: RouteListProps[] = [
  {
    sidebarTitle: 'Users',
    icon: UserIcon,
    activeIcon: WhiteUserIcon,
    subKey: '1',
    sidebarList: [
      {
        label: 'Administrator',
        permission: [Role.Admin],
        route: '',
        icon: SolutionOutlined,
        key: '1'
      },
      {
        label: 'Business',
        permission: [Role.Admin],
        route: '/users/business',
        icon: UserAddOutlined,
        key: '2'
      },
      {
        label: 'Customer',
        permission: [Role.Admin],
        route: '/users/customer',
        icon: TeamOutlined,
        key: '3'
      },
      {
        label: 'Members',
        permission: [Role.Admin],
        route: '/users/members',
        icon: TeamOutlined,
        key: '4'
      }
    ]
  },
  {
    sidebarTitle: 'Settings',
    icon: SettingsIcon,
    activeIcon: SettingsWhiteIcon,
    subKey: '2',
    sidebarList: [
      {
        label: 'Tracking',
        permission: [Role.Admin],
        route: '/settings/tracking',
        icon: SolutionOutlined,
        key: '5'
      }
    ]
  },
  // {
  //     sidebarTitle: "Resources",
  //     icon: ResourceIcon,
  //     key: "6",
  //     activeIcon:ResourceWhite,
  //     route:'/resources'
  // },

  {
    sidebarTitle: 'CMS',
    icon: DashboardIcon,
    activeIcon: SettingsWhiteIcon,
    subKey: '3',
    sidebarList: [
      {
        label: 'Resources',
        permission: [Role.Admin],
        icon: SolutionOutlined,
        key: '6',
        route: '/resources'
      },
      {
        label: 'WorkShops',
        permission: [Role.Admin],
        icon: VideoCameraOutlined,
        key: '9',
        route: '/workshops'
      },
      {
        label: 'Author',
        permission: [Role.Admin],
        route: '/author',
        icon: UserAddOutlined,
        key: '7'
      },
      {
        label: 'Videos',
        permission: [Role.Admin],
        route: '/videos',
        icon: VideoCameraAddOutlined,
        key: '8'
      },
      {
        label: 'Course',
        permission: [Role.Admin],
        route: '/course',
        icon: VideoCameraAddOutlined,
        key: '10'
      },
      {
        label: 'Blogs',
        permission: [Role.Admin],
        route: '/blogs',
        icon: ReadOutlined,
        key: '11'
      },
      {
        label: 'Messages',
        permission: [Role.Admin],
        route: '/broadcastmessages',
        icon: MessageOutlined,
        key: '12'
      },
      {
        label: 'Posts',
        permission: [Role.Admin],
        route: '/posts',
        icon: FormOutlined,
        key: '13'
      },
      {
        label: 'Home',
        permission: [Role.Admin],
        route: '/home',
        icon: HomeOutlined,
        key: '14'
      }
    ]
  }
];

export interface cardItemType {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  phoneCountryCode: string;
  phoneNumber: string;
  timeZone: string;
  avatar?: string;
  userStatus: string;
  createdAt: string;
  type: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface customerMemberType {
  parent?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  memberShipStart?: string;
  memberShipEnd?: string;
  listingType?: string;
  listingIdentifier?: string;
}

export interface memberPaginationType {
  totalCount: number;
  currentCount: number;
}

export interface userMemberDataType {
  userList?: Array<cardItemType>;
  memberList?: Array<any>;
  memberData?: Array<any>;
  memberLoading?: boolean;
  totalMemberCount?: number;
  memberCurrentPage?: number;
  loginUserData?: any;
  currentLoginUser?: any;
}

export interface memberItemType {
  parent: string | null;
  firstName: string;
  lastName: string;
  dob: string;
  memberShipStart: string;
  memberShipEnd: string;
  listingType: string;
  listingIdentifier: string;
  formRef?: null;
}

export interface memberDataType {
  createdAt: string;
  createdBy: string;
  dob: string;
  firstName: string;
  lastName: string;
  listingIdentifier: string;
  listingType: string;
  parent?: cardItemType;
  updatedAt: string;
  __v: 0;
  _id: string;
}

export const formDataList = [
  {
    name: 'Child Onboarding Profile',
    type: Role.Member,
    icon: 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1599472801495x617009282046887600%2Ficon8.jpg?w=96&h=96&auto=compress&fit=crop&dpr=1',
    formFields: [
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: "Child's Preferred Name",
        value: '',
        rule: ['required']
      },
      {
        tagType: 'input',
        type: 'number',
        isTextArea: false,
        editable: false,
        label: "Child's Age in years",
        value: '',
        rule: ['required']
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'What is the Level of Formal Diagnosis your child may have received?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Level 1 - Mild Autism',
            value: 'Level 1 - Mild Autism'
          },
          {
            label: 'Level 2 - Moderate Autism',
            value: 'Level 2 - Moderate Autism'
          },
          {
            label: 'Level 3 - Severe Autism',
            value: 'Level 3 - Severe Autism'
          },
          {
            label: 'Not Sure...',
            value: 'Not Sure...'
          }
        ]
      },
      {
        tagType: 'textarea',
        type: 'text',
        editable: false,
        label: 'List All the things that your child enjoys doing (his interests)',
        value: '',
        rule: ['required']
      },
      {
        tagType: 'textarea',
        type: 'text',
        editable: false,
        label:
          'Is there anything that your child does, or the way he behaves that is quite unique and not normally observed in other kids of his age with ASD',
        value: '',
        rule: [''],
        placeholder: 'leave blank if nothing applies'
      },
      {
        tagType: 'textarea',
        type: 'text',
        editable: false,
        label:
          'If you are not his/her parent, what do you think someone else would love the most about your child?',
        value: '',
        rule: ['']
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label:
          'Do you believe your child is a Visual thinker? A visual thinker is a person who responds much better to picture, graphs and images rather than words or non-verbal gestures',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Yes, definitely',
            value: 'Yes, definitely'
          },
          {
            label: 'Maybe, not sure',
            value: 'Maybe, not sure'
          },
          {
            label: "I don't think so",
            value: "I don't think so"
          }
        ]
      },
      {
        tagType: 'input',
        type: 'number',
        isTextArea: false,
        editable: false,
        label: 'How many friends does your child have, approximately?',
        rule: ['required'],
        value: '',
        placeholder: 'total number of friends in daycare, school, pre-school, etc'
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'Is your child good with Eye Contact?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: 'No',
            value: 'No'
          },
          {
            label: 'Not sure',
            value: 'Not sure'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'Please rate the verbal level of your child',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Non verbal',
            value: 'Non verbal'
          },
          {
            label: "Tries to speak but con't",
            value: "Tries to speak but con't"
          },
          {
            label: 'Speaks random words',
            value: 'Speaks random words'
          },
          {
            label: 'Speaks only 1 or 2 words that makes sense',
            value: 'Speaks only 1 or 2 words that makes sense'
          },
          {
            label: 'Can say simple 2 - 3 word sentences',
            value: 'Can say simple 2 - 3 word sentences'
          },
          {
            label: 'Basic communication but not complex sentences',
            value: 'Basic communication but not complex sentences'
          },
          {
            label: 'My child is pretty conversant',
            value: 'My child is pretty conversant'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'How comfortable is your child in using computer / smartphones / tablets?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Very Proficient',
            value: 'Very Proficient'
          },
          {
            label: 'Can use them somewhat',
            value: 'Can use them somewhat'
          },
          {
            label: "Does't like using technology",
            value: "Does't like using technology"
          },
          {
            label: 'Not sure',
            value: 'Not sure'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'List your Top 3 Expectations out of this program.',
        value: '',
        rule: ['required']
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'Goal 1:',
        value: '',
        comment: '',
        rule: ['required'],
        placeholder: 'tell us a bit more about what you want to achieve as your # 1 goal',
        listingData: [
          {
            label: "I want to improve my child's motor skills and hand-eye coordination",
            value: "I want to improve my child's motor skills and hand-eye coordination"
          },
          {
            label: 'I want to manage his/her behavior challenges',
            value: 'I want to manage his/her behavior challenges'
          },
          {
            label: 'I want improve his/her learning skills',
            value: 'I want improve his/her learning skills'
          },
          {
            label:
              'I want my child to become independent in his/her self-care or daily living activity ',
            value:
              'I want my child to become independent in his/her self-care or daily living activity '
          },
          {
            label:
              'I want to help my child with his her sensory discomfort (sound, touch, smell, etc)',
            value:
              'I want to help my child with his her sensory discomfort (sound, touch, smell, etc)'
          },
          {
            label: "I want to improve my child's eating habits/limitations",
            value: "I want to improve my child's eating habits/limitations"
          },
          {
            label: 'I want my child fully Potty trained',
            value: 'I want my child fully Potty trained'
          },
          {
            label: 'I want my child to have more friends and be socially comfortable',
            value: 'I want my child to have more friends and be socially comfortable'
          },
          {
            label: 'I want my child to be more compliant and listen to my instructions',
            value: 'I want my child to be more compliant and listen to my instructions'
          },
          {
            label: "I want to improve my child's focus and reduce inattention",
            value: "I want to improve my child's focus and reduce inattention"
          },
          {
            label: "I want to improve my child's motor skills and hand-eye coordination",
            value: "I want to improve my child's motor skills and hand-eye coordination"
          },
          {
            label: "I want to become more proficient in handling my child's needs",
            value: "I want to become more proficient in handling my child's needs"
          },
          {
            label: 'Something else',
            value: 'Something else'
          },
          {
            label: 'I am not sure - want my coach to help me',
            value: 'I am not sure - want my coach to help me'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'Goal 2:',
        value: '',
        comment: '',
        rule: ['required'],
        placeholder:
          '"What does "success" look like for Goal 2 for you. Tell us what needs to happen in 6 months"',
        listingData: [
          {
            label: "I want to improve my child's motor skills and hand-eye coordination",
            value: "I want to improve my child's motor skills and hand-eye coordination"
          },
          {
            label: 'I want to manage his/her behavior challenges',
            value: 'I want to manage his/her behavior challenges'
          },
          {
            label: 'I want improve his/her learning skills',
            value: 'I want improve his/her learning skills'
          },
          {
            label:
              'I want my child to become independent in his/her self-care or daily living activity ',
            value:
              'I want my child to become independent in his/her self-care or daily living activity '
          },
          {
            label:
              'I want to help my child with his her sensory discomfort (sound, touch, smell, etc)',
            value:
              'I want to help my child with his her sensory discomfort (sound, touch, smell, etc)'
          },
          {
            label: "I want to improve my child's eating habits/limitations",
            value: "I want to improve my child's eating habits/limitations"
          },
          {
            label: 'I want my child fully Potty trained',
            value: 'I want my child fully Potty trained'
          },
          {
            label: 'I want my child to have more friends and be socially comfortable',
            value: 'I want my child to have more friends and be socially comfortable'
          },
          {
            label: 'I want my child to be more compliant and listen to my instructions',
            value: 'I want my child to be more compliant and listen to my instructions'
          },
          {
            label: "I want to improve my child's focus and reduce inattention",
            value: "I want to improve my child's focus and reduce inattention"
          },
          {
            label: "I want to improve my child's motor skills and hand-eye coordination",
            value: "I want to improve my child's motor skills and hand-eye coordination"
          },
          {
            label: "I want to become more proficient in handling my child's needs",
            value: "I want to become more proficient in handling my child's needs"
          },
          {
            label: 'Something else',
            value: 'Something else'
          },
          {
            label: 'I am not sure - want my coach to help me',
            value: 'I am not sure - want my coach to help me'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'Goal 3:',
        value: '',
        comment: '',
        rule: ['required'],
        placeholder:
          'please tell us a bit more about your Goal priority # 3. At minimum, what would you like to achieve',
        listingData: [
          {
            label: "I want to improve my child's motor skills and hand-eye coordination",
            value: "I want to improve my child's motor skills and hand-eye coordination"
          },
          {
            label: 'I want to manage his/her behavior challenges',
            value: 'I want to manage his/her behavior challenges'
          },
          {
            label: 'I want improve his/her learning skills',
            value: 'I want improve his/her learning skills'
          },
          {
            label:
              'I want my child to become independent in his/her self-care or daily living activity ',
            value:
              'I want my child to become independent in his/her self-care or daily living activity '
          },
          {
            label:
              'I want to help my child with his her sensory discomfort (sound, touch, smell, etc)',
            value:
              'I want to help my child with his her sensory discomfort (sound, touch, smell, etc)'
          },
          {
            label: "I want to improve my child's eating habits/limitations",
            value: "I want to improve my child's eating habits/limitations"
          },
          {
            label: 'I want my child fully Potty trained',
            value: 'I want my child fully Potty trained'
          },
          {
            label: 'I want my child to have more friends and be socially comfortable',
            value: 'I want my child to have more friends and be socially comfortable'
          },
          {
            label: 'I want my child to be more compliant and listen to my instructions',
            value: 'I want my child to be more compliant and listen to my instructions'
          },
          {
            label: "I want to improve my child's focus and reduce inattention",
            value: "I want to improve my child's focus and reduce inattention"
          },
          {
            label: "I want to improve my child's motor skills and hand-eye coordination",
            value: "I want to improve my child's motor skills and hand-eye coordination"
          },
          {
            label: "I want to become more proficient in handling my child's needs",
            value: "I want to become more proficient in handling my child's needs"
          },
          {
            label: 'Something else',
            value: 'Something else'
          },
          {
            label: 'I am not sure - want my coach to help me',
            value: 'I am not sure - want my coach to help me'
          }
        ]
      },
      {
        tagType: 'textarea',
        type: 'text',
        editable: false,
        label: 'What are you most excited about this Program?',
        value: '',
        rule: ['required']
      },
      {
        tagType: 'textarea',
        type: 'text',
        editable: false,
        label:
          'Please mention any other detail that may be valuable to your coach in the First session...',
        value: '',
        rule: [''],
        placeholder:
          'Please include anything that you believe is relevant to this program but not captured in this profile form'
      }
    ]
  },
  {
    name: 'Parent Onboarding Profile',
    type: Role.Customer,
    icon: 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1599472812481x564334952173687100%2Ficon7.jpg?w=96&h=96&auto=compress&fit=crop&dpr=1',
    formFields: [
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'Your Full Name',
        value: '',
        rule: ['required']
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'Is there a preferred name (or nick name) that you want to be called by?',
        value: '',
        rule: [''],
        placeholder: 'if NA, leave blank'
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'Which city/town/suburb do you currently live in?',
        value: '',
        rule: ['required']
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label:
          'Please paste your Full Facebook Profile Link (we will reach out to you via Facebook Messenger)',
        value: '',
        rule: ['required'],
        placeholder: 'https://www.facebook.com/ashforautism/'
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'What is your current parenting status?',
        value: '',
        comment: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Prefer not to say',
            value: 'Prefer not to say'
          },
          {
            label: 'Single parent',
            value: 'Single parent'
          },
          {
            label: 'Married or with partner',
            value: 'Married or with partner'
          },
          {
            label: "It's complicated",
            value: "It's complicated"
          },
          {
            label: 'Not a parent, primary caregiver',
            value: 'Not a parent, primary caregiver'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label: 'Are you a full time parent?',
        value: '',
        comment: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: 'No - I work part-time/casual',
            value: 'No - I work part-time/casual'
          },
          {
            label: 'No - I work full time',
            value: 'No - I work full time'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label:
          'How many kids (under 18 yo), including the child enrolled in the program are you a primary caregiver of?',
        value: '',
        comment: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Just 1',
            value: 'Just 1'
          },
          {
            label: '2 children',
            value: '2 children'
          },
          {
            label: '3 children',
            value: '3 children'
          },
          {
            label: 'more than 3 children',
            value: 'more than 3 children'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label:
          'When it comes to your child, what motivates you to keep giving your 100% on a day to day basis',
        value: '',
        rule: [''],
        placeholder: 'Leave blank if nothing applies'
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label:
          'Do you receive any support from your family / friends / community on a regular basis?',
        value: '',
        rule: [''],
        placeholder:
          'if not, please leave blank. If yes, please mention the kind of support you receive at the moment'
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label:
          'Are you able to manage enough time to take good care of yourself (exercises, yoga, healthy eating, spa, message, whatever)?',
        value: '',
        comment: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Yes, usually have no problems with that',
            value: 'Yes, usually have no problems with that'
          },
          {
            label: 'Not really, but sometimes I do',
            value: 'Not really, but sometimes I do'
          },
          {
            label: 'No, I get too busy managing my priorities',
            value: 'No, I get too busy managing my priorities'
          }
        ]
      },
      {
        tagType: 'select',
        type: 'select',
        isTextArea: false,
        isSelect: true,
        editable: false,
        label:
          'On a scale of 1 (terrible) to 10 (excellent), how do you generally feel with things happening around you?',
        value: '',
        comment: '',
        rule: ['required'],
        listingData: [
          {
            label: '1 (terrible)',
            value: '1 (terrible)'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '5',
            value: '5'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '7',
            value: '7'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '9',
            value: '9'
          },
          {
            label: '10 (excellent)',
            value: '10 (excellent)'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'If you had a magic wand, what would you love to change about yourself?',
        value: '',
        rule: [''],
        placeholder: 'leave blank if nothing comes to mind'
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label:
          'Do you have any specific message for your Mindset Coach before the start of your first session?',
        value: '',
        rule: [''],
        placeholder:
          'please enter any message that you want to share with your counsellor in confidence'
      }
    ]
  },
  {
    name: 'Social Awareness Assessment',
    type: Role.Member,
    icon: 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1599472823549x797139297970664800%2Ficon6.jpg?w=96&h=96&auto=compress&fit=crop&dpr=1',
    formFields: [
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Does your child,'
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '1. Work and play well with others?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '2. Listen attentively?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '3. Respect his/her own property?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '4. Follow rules of the classroom/school?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '5. Assume appropriate levels of responsibility?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '6. Show consideration for others?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '7. Show self-control?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '8. Ask for help in an appropriate way?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '9. Offer help to others?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '10. Express anger appropriately?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '11. Make decisions for self?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '12. Express compliments to others?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '13. Make friends easily?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '14. Work constructively as a group member?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '15. Interpret body language effectively?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '16. Accomplish tasks on her/her own?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '17. Compromise/negotiate effectively?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '18. Take turns in conversation?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '19. Demonstrate appropriate hygiene?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: '20. Take ownership for mistakes?',
        value: '',
        rule: ['required'],
        listingData: [
          {
            label: 'Never',
            value: 'Never'
          },
          {
            label: 'Rarely',
            value: 'Rarely'
          },
          {
            label: 'Sometimes',
            value: 'Sometimes'
          },
          {
            label: 'Usually',
            value: 'Usually'
          },
          {
            label: 'Always',
            value: 'Always'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'Total Computed Value:',
        value: '',
        rule: ['required']
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'Aggregated Score (out of 10) :',
        value: '',
        rule: ['required']
      }
    ]
  },
  {
    name: 'Sensory Profile Asessment',
    type: Role.Member,
    icon: 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1599472801495x617009282046887600%2Ficon8.jpg?w=96&h=96&auto=compress&fit=crop&dpr=1',
    formFields: [
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Tactile'
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Avoids casual touch by peers pr adults',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Craves physical contact',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Hugs very tightly',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Distressed by messy hands',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes certail textures',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Craves certain textures',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Sucks/chews clothing',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Sucks/chews self',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Sucks/chews objects',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Removes clothing',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Prefers bare foot',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Distressed at hair brushing, teeth cleaning',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes cold',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes hot',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Craves cold',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Craves hot',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Disturbed by vibration',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Tactile stims - tapping, rubbing, squeezing, banging, scratching',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Vision'
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Makes poor eye contact',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Squints, blinks or rubs eyes frequently',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Covers eyes',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Difficulty with hand eye coordination',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Difficulty copying from board',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Distracted by lights',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes flickering lights',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Fascinated by shiny objectsor bright colours',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Distressed with dark spaces',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Struggles to follow moving objects or people',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Over stimulated in  busy environments',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Cautious of changes in floor surfaces',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Enjoys certain patterns',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Appears not to see certain colours',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Uses peripheral vision when doing task',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Visual stims - hand flipping, flicking fingers in front of eyes, spins objects',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Vestibular/Balance'
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Avoids changes in head position',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Seems clumsy/moves awkwardly',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Excessively cautious on stairs',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Become anxious or distressed when feet leave the ground',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Slumps in chair',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Sits in W position',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Need support for floor sitting',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Touches furniture or walls when walking',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Toe walks',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Rocks in chair',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Wraps legs around chair',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'May fall out of chair',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Leaning on something or someone whilst sitting on floor',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Has poor balance',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Has extremely good balance',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Fidgets constantly',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Appears always on the go',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: "Appears passive and hard to 'wake up' ",
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Gets dizzy easily',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Avoids being on moving objects',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Craves being on moving objects',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Vestibular stims- spinning, rocking, jumping',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Auditory'
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Distressed by load noises',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Likes loud noises',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Disturbed by sounds such as musical instruments and singing',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Craves by sounds suchs as musical instruments and singing',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Covers ears',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Speaks with loud voice',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Speaks with unusually quiet voice',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: "Doesn't seem to hear you",
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Difficulty filtering out noise',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Frequent outbursts in certain environments',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Seems to learn more easily in 1-1 situations than in a group',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Very good auditory memory for songs',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label:
          'Auditory stims- hums, repeats, makes odd noises, tapping ears, snapping fingers, making vocalisations',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Proprioception'
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Poor body awareness',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Cannot identify body parts',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Bumps into things',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Difficulty grading force - breaks things easily',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Drops items',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Has a weak grasp',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Crashes and falls purpose',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Lays down on floor at inappropriate times',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Tires easily',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Props to support self',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Seeks deep pressure',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Likes being upside down',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Proprioceptive stims: shaking',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'label',
        type: 'label',
        isTextArea: false,
        editable: false,
        label: 'Smell and Taste',
        value: '',

        comment: ''
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Complains about smells',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Complains about tastes',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes certain everyday smells',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes crunchy/dry foods',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Prefers crunchy/dry foods',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Dislikes chewy foods',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Prefers chewy foods',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Likes food displayed on plate in a particular way',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Picky eater',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Self-limited diet',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Eats non edible materials',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Mouths or licks objects or people',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: 'Smells objects and people',
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'radio',
        isTextArea: false,
        editable: false,
        label: "Doesn't seem to notice strong odours",
        value: '',
        comment: '',
        listingData: [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: "Don't know",
            value: "Don't know"
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      },
      {
        tagType: 'input',
        type: 'text',
        isTextArea: false,
        editable: false,
        label: 'Total Score',
        value: '',
        comment: ''
      }
    ]
  }
];

export const goalFormData = {
  goalName: '',
  goalTypeList: [
    {
      title: 'Communication Skills',
      value: 'Communication Skills'
    },
    {
      title: 'BehaviorEmotional Skills',
      value: 'BehaviorEmotional Skills'
    },
    {
      title: 'Teaching & Learning',
      value: 'Teaching & Learning'
    },
    {
      title: 'Social Skills',
      value: 'Social Skills'
    },
    {
      title: 'Cognitive Skills',
      value: 'Cognitive Skills'
    },
    {
      title: 'Daily Living Skills',
      value: 'Daily Living Skills'
    },
    {
      title: 'Sensory Regulation',
      value: 'Sensory Regulation'
    },
    {
      title: 'Others (specify)',
      value: 'Others'
    }
  ],
  complexity: [
    {
      title: 'Simple',
      value: 'Simple'
    },
    {
      title: 'Moderate',
      value: 'Moderate'
    },
    {
      title: 'Complex',
      value: 'Complex'
    },
    {
      title: 'Highly Ambitious',
      value: 'Highly Ambitious'
    }
  ],
  skillsInfo: ''
};

export const dashBoardFilterByDateType = [
  {
    name: 'Today',
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString()
  },
  {
    name: 'This Week',
    startDate: moment().startOf('isoWeek').toISOString(),
    endDate: moment().endOf('isoWeek').toISOString()
  },
  {
    name: 'Last Week',
    startDate: moment().subtract(1, 'weeks').startOf('isoWeek').toISOString(),
    endDate: moment().subtract(1, 'weeks').endOf('isoWeek').toISOString()
  },
  {
    name: 'This Month',
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString()
  },
  {
    name: 'Last Month',
    startDate: moment().subtract(1, 'months').toISOString(),
    endDate: moment().endOf('day').toISOString()
  },
  {
    name: 'Last 3 Months',
    startDate: moment().subtract(3, 'months').toISOString(),
    endDate: moment().endOf('day').toISOString()
  },
  {
    name: 'Last 6 Months',
    startDate: moment().subtract(6, 'months').toISOString(),
    endDate: moment().endOf('day').toISOString()
  },
  {
    name: 'All Time',
    startDate: moment('1/1/2000').toISOString(),
    endDate: moment().endOf('day').toISOString()
  }
];

// export const itemRender =(current, type, originalElement) =>{
//     if (type === 'prev') {
//       return 'prev';
//     }
//     if (type === 'next') {
//       return 'next';
//     }
//     return originalElement;
//   }

export const paginationConfig: false | TablePaginationConfig | PaginationLocale = {
  size: 'small',
  showQuickJumper: true

  // itemRender: itemRender
};

export const DateFormatToEsdt = (date) =>
  new Date(date).toLocaleString('en-US', {
    timeZone: 'Australia/Sydney'
  });

export const APP_URL_GOOGLE = 'https://play.google.com/store/apps/details?id=com.viaapp';
export const APP_URL_APPLE = 'https://apps.apple.com/au/app/autism360/id1601197707';
export const CRISP_WEBSITE_ID = '5641ac82-f5e2-4cbc-95f4-fb2b310f02d5';

export const COPYRIGHT_YEAR = new Date().getFullYear();
