import { CopyOutlined } from '@ant-design/icons';
import { Divider, Layout, Menu, message, Select, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getNotificationApi } from '../../api/notification';
import { getSessionApi } from '../../api/session';
import { getPublicUserApi, getUserApi } from '../../api/user';
import { getGoalsApi } from '../../api/user-program';
import AdministratorComponent from '../../components/AdministratorComponent';
import BusinessComponent from '../../components/BusinessComponent';
import BrandingHeader from '../../components/common/BrandingHeader';
import CustomerAccessBackButton from '../../components/common/CustomerAccessBackButton';
import { UnderDevelopmentDiv } from '../../components/common/styles';
import { getDataFromLocalStorage, setDataInLocalStorage } from '../../components/common/utils';
import CustomerComponent from '../../components/CustomerComponent';
import MembersComponent from '../../components/MembersComponent';
import MileStoneComponent from '../../components/MileStoneComponent';
import MyFavoriteComponent from '../../components/MyFavoriteComponent';
import MyGoalsComponent from '../../components/MyGoalsComponent';
import MyLearningComponent from '../../components/MyLearningComponent';
import MyNotificationComponent from '../../components/MyNotificationComponent';
import MyResourcesComponent from '../../components/MyResources/MyResourcesComponent';
import MySessionComponent from '../../components/MySessionComponent';
import ParentOnboardingComponent from '../../components/ParentOnboardingComponent';
import ResourcesComponent from '../../components/ResourcesComponent';
import EventRoute from '../../components/Routes/EventRoute';
import MyFormTableComponent from '../../components/TableData/MyFormTableComponent';
import TrackingComponent from '../../components/Tracking/TrackingComponent';
import UnderDevelopmentComponent from '../../components/UnderDevelopmentComponent';
import {
  AdminRouteList,
  BussinessRouteList,
  COPYRIGHT_YEAR,
  CustomerRouteList
} from '../../constant';
import DownIcon from '../../images/icons/downarrow.svg';
import logoText from '../../images/icons/logo-text.png';
import UserWhite from '../../images/icons/userWhite.svg';
import {
  setCustomerAccessData,
  setCustomerLoading,
  setSelectedMemberData,
  setUserLocalData
} from '../../redux/actions/customerAction';
import { getNotificationAlert } from '../../redux/actions/notificationAction';
import { setSessionData } from '../../redux/actions/sessionAction';
import { setCurrentLoginUser, setLoginUserData } from '../../redux/actions/userMemberAction';
import { setMemberGoals } from '../../redux/actions/userProgramAction';
import { logOut } from '../../utils/api';
import { Role } from '../../utils/types/role';
import {
  BlockHeader,
  DashboardLayout,
  DropDownSelect,
  MenuButton,
  SelectUserIcon,
  SideBarFooter,
  SideIcon
} from './styles';
import WelcomeModal from './WelcomeModal';
// import logoText from "../../images/icons/logo-360-text.png";
import AuthorComponent from '../../components/AuthorComponent';
import BlogComponent from '../../components/BlogComponent';
import BroadcastMessageComponent from '../../components/BroadcastMessageComponent';
import ClientFilesBinComponent from '../../components/ClientFilesBinComponent';
import ClientFilesComponent from '../../components/ClientFilesComponent';
import CourseComponent from '../../components/CourseComponent';
import HomeComponent from '../../components/Home/Home';
import PostComponent from '../../components/PostComponent';
import ResourceObjectiveComponent from '../../components/ResourceObjectives/ResourceObjectiveComponent';
import SharedVideosComponent from '../../components/SharedVideos/SharedVideosComponent';
import VideoCategoriesComponent from '../../components/VideoCategories/VideoCategoriesComponent';
import VideoComponent from '../../components/VideoComponent';
import WorkShopComponent from '../../components/WorkShopComponent';
import hamburgerIcon from '../../images/icons/hamburger-icon.svg';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const { Title } = Typography;

const Dashboard: React.FC<any> = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [chooseKey, setKey] = useState<string>('Active');
  const [collapse, setCollapse] = useState<boolean>(false);
  const [showLogo, setLogo] = useState<boolean>(false);
  const [userType, setUserType] = useState<string>('');
  const [menuValue, setMenuValue] = useState<any>(['1']);
  const [subMenuValue, setSubMenuValue] = useState<any>(['sub1']);
  const [openKeys, setOpenKeys] = useState<any>([]);
  const [customerSelectedMember, setCustomerSelectedMember] = useState<any>([]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const customerAccessInfo = getDataFromLocalStorage('bussness access customer');
  const selectedMember = getDataFromLocalStorage('selected member');

  const {
    dispatch,
    loginUserData,
    currentLoginUser,
    customerAccessData,
    userLocalData,
    herderLabel,
    isMobile,
    MemberData
  } = props;

  const fetchUserById = (id: string = '') => getUserApi(id);

  const handlescreenSize = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth <= 1042) {
      setCollapse(true);
    }
    if (window.innerWidth > 1042) {
      setCollapse(false);
    }
  };

  useEffect(() => {
    const crisp = (window as any).$crisp;
    if (crisp) {
      crisp.push([
        'set',
        'user:nickname',
        [`${currentLoginUser.firstName} ${currentLoginUser.lastName}`]
      ]);
      crisp.push(['set', 'user:email', [currentLoginUser.email]]);
      if (currentLoginUser.avatar) {
        crisp.push(['set', 'user:avatar', [currentLoginUser.avatar]]);
      }
    }
  }, []);
  useEffect(() => {
    handlescreenSize();
    window.addEventListener('resize', handlescreenSize);
    return () => {
      window.removeEventListener('resize', handlescreenSize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    // if (currentLoginUser && currentLoginUser.firstTimeLogin === true) {
    //   patchFirstLogin(currentLoginUser._id).then((res) => {});
    // }
    getNotificationApi(currentLoginUser._id).then((res) => {
      const { data } = res;
      dispatch(getNotificationAlert(data));
    });
  }, []);

  useEffect(() => {
    setCustomerSelectedMember(selectedMember);
    dispatch(setCustomerAccessData(customerAccessInfo));
    if (
      currentLoginUser.type === Role.Customer &&
      currentLoginUser.members &&
      currentLoginUser.members.length
    ) {
      const defaultSelectedMember =
        currentLoginUser &&
        currentLoginUser.members.filter(
          (data: any) => data._id === currentLoginUser.members[0]._id
        );
      const defaultMemberId =
        selectedMember && selectedMember.length
          ? selectedMember[0]._id
          : defaultSelectedMember[0]._id;
      if (selectedMember) {
        // dispatch(setSelectedMemberData(selectedMember));
        if (selectedMember[0].primaryContact) {
          getPublicUserApi(selectedMember[0].primaryContact, 'BusinessUser').then((response) => {
            dispatch(
              setSelectedMemberData([{ ...selectedMember[0], primaryContactObject: response }])
            );
          });
        } else {
          dispatch(
            setSelectedMemberData([
              {
                ...selectedMember[0],
                primaryContactObject: { firstName: ' ' + '---', lastName: '' }
              }
            ])
          );
        }
      } else {
        dispatch(setSelectedMemberData(defaultSelectedMember));
      }
      dispatch(setCustomerLoading(true));
      getGoalsApi(defaultMemberId).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
      getSessionApi(defaultMemberId).then((res) => {
        dispatch(setSessionData(res.data));
        dispatch(setCustomerLoading(false));
      });
    }

    if (customerAccessInfo && customerAccessInfo.id) {
      dispatch(setCustomerLoading(true));
      fetchUserById(customerAccessInfo.id).then((res) => {
        dispatch(setUserLocalData(res));
        dispatch(setLoginUserData(res));
        const params = new URLSearchParams(window.location.search);
        const memberId = params.get('memberId');
        const defaultMember =
          res.members.find((data: any) => data._id === memberId) ||
          selectedMember?.[0] ||
          res.members[0];

        setDataInLocalStorage('selected member', [defaultMember]);
        // dispatch(setSelectedMemberData(selectedMember));
        if (defaultMember?.primaryContact) {
          getPublicUserApi(defaultMember?.primaryContact, 'BusinessUser').then((response) => {
            dispatch(setSelectedMemberData([{ ...defaultMember, primaryContactObject: response }]));
          });
        } else {
          dispatch(
            setSelectedMemberData([
              {
                ...defaultMember,
                primaryContactObject: { firstName: ' ' + '---', lastName: '' }
              }
            ])
          );
        }

        if (defaultMember) {
          getGoalsApi(defaultMember._id).then((res) => {
            dispatch(setMemberGoals(res.data));
            dispatch(setCustomerLoading(false));
          });
          getSessionApi(defaultMember._id).then((res) => {
            dispatch(setSessionData(res.data));
            dispatch(setCustomerLoading(false));
          });
        }
      });
    } else {
      dispatch(setLoginUserData(currentLoginUser));
      dispatch(setCustomerLoading(false));
    }
    setUserType(currentLoginUser.type);
  }, [history]);

  const handleChange = () => {
    setCollapse(!collapse);
    if (showLogo) {
      setTimeout(() => setLogo(false), 300);
    } else {
      setLogo(true);
    }
  };
  const handleSelect = (value: any) => {
    // dispatch(setCustomerLoading(true));

    if (customerAccessInfo && customerAccessInfo.id) {
      dispatch(setCustomerLoading(true));
      const selectedTypeData = loginUserData?.members?.filter((data: any) => data._id === value);
      if (selectedTypeData && selectedTypeData.length) {
        setDataInLocalStorage('selected member', selectedTypeData);
        // dispatch(setSelectedMemberData(selectedTypeData));
        if (selectedTypeData[0].primaryContact) {
          getPublicUserApi(selectedTypeData[0].primaryContact, 'BusinessUser').then((response) => {
            dispatch(
              setSelectedMemberData([{ ...selectedTypeData[0], primaryContactObject: response }])
            );
          });
        } else {
          dispatch(
            setSelectedMemberData([
              {
                ...selectedTypeData[0],
                primaryContactObject: { firstName: ' ' + '---', lastName: '' }
              }
            ])
          );
        }
        const selectedMemberId = selectedTypeData[0]._id;
        getGoalsApi(selectedMemberId)
          .then((res) => {
            dispatch(setMemberGoals(res.data));
            dispatch(setCustomerLoading(false));
          })
          .catch((err) => dispatch(setCustomerLoading(false)));
        getSessionApi(selectedMemberId)
          .then((res) => {
            dispatch(setSessionData(res.data));
            dispatch(setCustomerLoading(false));
          })
          .catch((err) => dispatch(setCustomerLoading(false)));
      }
    }

    if (currentLoginUser.type === Role.Customer && currentLoginUser._id) {
      fetchUserById(currentLoginUser._id)
        .then((res) => {
          dispatch(setCustomerLoading(true));
          const localData = {
            ...currentLoginUser,
            forms: res.forms,
            members: res.members
          };
          dispatch(setCurrentLoginUser(localData));

          const selectedTypeData = res.members.filter((data: any) => data._id === value);
          if (selectedTypeData && selectedTypeData.length) {
            setDataInLocalStorage('selected member', selectedTypeData);
            // dispatch(setSelectedMemberData(selectedTypeData));
            if (selectedTypeData[0].primaryContact) {
              getPublicUserApi(selectedTypeData[0].primaryContact, 'BusinessUser').then(
                (response) => {
                  dispatch(
                    setSelectedMemberData([
                      {
                        ...selectedTypeData[0],
                        primaryContactObject: response
                      }
                    ])
                  );
                }
              );
            } else {
              dispatch(
                setSelectedMemberData([
                  {
                    ...selectedTypeData[0],
                    primaryContactObject: {
                      firstName: ' ' + '---',
                      lastName: ''
                    }
                  }
                ])
              );
            }

            const selectedMemberId = selectedTypeData[0]._id;
            getGoalsApi(selectedMemberId)
              .then((res) => {
                dispatch(setMemberGoals(res.data));
                dispatch(setCustomerLoading(false));
              })
              .catch((err) => dispatch(setCustomerLoading(false)));
            getSessionApi(selectedMemberId)
              .then((res) => {
                dispatch(setSessionData(res.data));
                dispatch(setCustomerLoading(false));
              })
              .catch((err) => dispatch(setCustomerLoading(false)));
          }
        })
        .catch((err) => dispatch(setCustomerLoading(false)));
    }
  };

  const routes = [
    {
      path: `${match.path}/customer:id`,
      component: ParentOnboardingComponent
    },
    {
      path: `${match.path}/business:id`,
      component: BusinessComponent
    },
    {
      path: `${match.path}/users/business`,
      component: BusinessComponent,
      roles: [Role.Admin]
    },
    {
      path: `${match.path}/users/customer`,
      component: CustomerComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/users/members`,
      component: MembersComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/settings/tracking`,
      component: TrackingComponent,
      roles: [Role.Admin]
    },
    {
      path: `${match.path}/myprogram`,
      component: MyGoalsComponent
    },
    {
      path: `${match.path}/myprogram/customer:id`,
      component: MyGoalsComponent
    },
    {
      path: `${match.path}/myprogram/:goalName`,
      component: MileStoneComponent
    },
    {
      path: `${match.path}/myforms/:type?`,
      component: MyFormTableComponent
    },
    {
      path: `${match.path}/mysessions`,
      component: MySessionComponent
    },
    {
      path: `${match.path}/resources`,
      component: ResourcesComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/resources/categories`,
      component: ResourceObjectiveComponent,
      roles: [Role.Admin]
    },
    {
      path: `${match.path}/workshops`,
      component: WorkShopComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/author`,
      component: AuthorComponent,
      roles: [Role.Admin]
    },
    {
      path: `${match.path}/course`,
      component: CourseComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/videos`,
      component: VideoComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/videos/categories`,
      component: VideoCategoriesComponent,
      roles: [Role.Admin]
    },
    {
      path: `${match.path}/blogs`,
      component: BlogComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/broadcastmessages`,
      component: BroadcastMessageComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/posts`,
      component: PostComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/resources/:id`,
      component: ResourcesComponent,
      roles: [Role.Business, Role.Admin]
    },
    {
      path: `${match.path}/myresources`,
      component: MyResourcesComponent
    },
    {
      path: `${match.path}/clientFiles`,
      component: ClientFilesComponent
    },
    {
      path: `${match.path}/clientFiles/bin`,
      component: ClientFilesBinComponent
    },
    {
      path: `${match.path}/myfavorites`,
      component: MyFavoriteComponent
    },
    {
      path: `${match.path}/mynotifications`,
      component: MyNotificationComponent
    },
    {
      path: `${match.path}/mylearning`,
      component: MyLearningComponent
    },
    {
      path: `${match.path}/event-callback/:id/:type`,
      component: EventRoute
    },
    {
      path: `${match.path}/sharedVideos`,
      component: SharedVideosComponent
    },
    {
      path: `${match.path}/home`,
      component: HomeComponent,
      roles: [Role.Business, Role.Admin]
    }
  ];

  const tabsMap: any = {
    '/dashboard': ['1'],
    '/dashboard/users/business': ['2'],
    '/dashboard/users/customer': ['3'],
    '/dashboard/users/members': ['4'],
    '/dashboard/settings/tracking': ['5'],
    '/dashboard/resources': ['6'],
    '/dashboard/author': ['7'],
    '/dashboard/videos': ['8'],
    '/dashboard/resources/id': ['6'],
    '/dashboard/workshops': ['9'],
    '/dashboard/course': ['10'],
    '/dashboard/blog': ['11'],
    '/dashboard/broadcastmessages': ['12'],
    '/dashboard/posts': ['13']
  };

  const tabsSubMap: any = {
    '/dashboard': ['sub1'],
    '/dashboard/users/business': ['sub1'],
    '/dashboard/users/customer': ['sub1'],
    '/dashboard/users/members': ['sub1'],
    '/dashboard/settings/tracking': ['sub2'],
    '/dashboard/resources': [],
    '/dashboard/resources/id': []
  };

  const customerTabs: any = {
    '/dashboard': ['1'],
    '/dashboard/myforms': ['2'],
    '/dashboard/myprogram': ['3'],
    '/dashboard/mysessions': ['4'],
    // "/dashboard/myfavorites": ["5"],
    '/dashboard/mylearning': ['5'],
    '/dashboard/myresources': ['6'],
    '/dashboard/sharedVideos': ['7'],
    '/dashboard/clientFiles': ['8'],
    '/dashboard/myapps': ['9'],
    '/dashboard/mynotifications': ['10']
  };

  const BusinessTabs: any = {
    '/dashboard': ['1'],
    '/dashboard/users/members': ['2'],
    '/dashboard/resources': ['3'],
    '/dashboard/resources/id': ['3'],
    '/dashboard/videos': ['4'],
    '/dashboard/workshops': ['5'],
    '/dashboard/course': ['6'],
    '/dashboard/blog': ['7'],
    '/dashboard/broadcastmessages': ['8']
  };
  const BusinessSubTabs: any = {
    '/dashboard/resources': ['sub3'],
    '/dashboard/resources/id': ['sub3'],
    '/dashboard/videos': ['sub3'],
    '/dashboard/workshops': ['sub3'],
    '/dashboard/course': ['sub3']
  };

  const getCurrentActiveTab = (path: string | number) => {
    switch (userType) {
      case Role.Admin:
        return customerAccessData && customerAccessData.role === Role.BusinessAccesCustomer
          ? customerTabs[path] || ['0']
          : tabsMap[path] || ['0'];
      case Role.Business:
        return customerAccessData && customerAccessData.role === Role.BusinessAccesCustomer
          ? customerTabs[path] || ['0']
          : BusinessTabs[path] || ['0'];
      default:
        return customerTabs[path] || ['0'];
    }
  };

  const getActiveCollapsabeTab = (path: string | number) => {
    switch (userType) {
      case Role.Admin:
        return tabsSubMap[path] || ['0'];
      case Role.Business:
        return BusinessSubTabs[path] || ['0'];
    }
  };

  const onLogoutClick = () => {
    logOut();
    history.push('/login');
  };

  useEffect(() => {
    const pathname = herderLabel || window.location.pathname;
    const defaultRoute: any = getCurrentActiveTab(pathname);
    const defaultSubRoute: any = getActiveCollapsabeTab(pathname);
    setMenuValue(defaultRoute);
    setOpenKeys(defaultSubRoute);
  }, [herderLabel, userType, history]);

  const handleClickSideBar = (e) => {
    if (e.key) {
      setMenuValue([e.key]);
    }
    if (e.keyPath.length <= 1) {
      setOpenKeys([]);
      {
        (isMobile || screenSize < 1042) && setCollapse(!collapse);
      }
    }

    if (e.keyPath && e.keyPath[1]) {
      setSubMenuValue([e.keyPath[1]]);
      {
        isMobile || (screenSize < 1042 && setCollapse(!collapse));
      }
    }
  };

  const handleChangeOpen = (keys) => {
    setOpenKeys(keys.filter((val) => val != openKeys));
  };

  // const getCurrentActiveTab = (path: string | number) => (userType === (Role.Admin || Role.Business) ? (tabsMap[path] || ['0']) : (customerTabs[path] || ['0']));
  // const defaultSelectedKeys = getCurrentActiveTab(window.location.pathname);
  const loginUserDataList =
    loginUserData && loginUserData.members && loginUserData.members.length
      ? loginUserData.members
      : [];
  const customerAccessUserType = (customerAccessData && customerAccessData.role) || '';

  const checkPermission = (data: any, index: number) => {
    const filteredIndex = data.permission.findIndex((perm: any) => perm === userType);
    const Icon = data.icon;
    if (filteredIndex > -1 && filteredIndex < data.permission.length) {
      return (
        <Menu.Item key={data.key}>
          <SideIcon>
            <Icon />
          </SideIcon>
          <Link to={`${match.path}${data.route}`}>{data.label}</Link>
        </Menu.Item>
      );
    }
  };

  const checkPermissionAdmin = (data: any, index: number) => {
    const filteredIndex = data.permission.findIndex((perm: any) => perm === userType);
    const Icon = data.icon;
    if (filteredIndex > -1 && filteredIndex < data.permission.length) {
      return (
        <Menu.Item key={data.key}>
          <SideIcon>
            <Icon />
          </SideIcon>
          <Link to={`${match.path}${data.route}`}>{data.label}</Link>
        </Menu.Item>
      );
    }
  };
  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const memberCalculatedAge = () => {
    const a = moment();
    const b = moment(MemberData[0]?.dob) || moment(MemberData?.dob);

    if (!(MemberData[0]?.dob || MemberData?.dob)) {
      return '';
    }
    const years = a.diff(b, 'year');
    b.add(years, 'years');

    const months = a.diff(b, 'months');
    b.add(months, 'months');

    const days = a.diff(b, 'days');
    if (years >= 2) {
      return `${years} years `;
    }
    if (years === 1) {
      return `${years} year `;
    }
    if (months <= 1) {
      return `${months} month`;
    }
    return `${months} months`;
  };

  const renderDashBoard = (accessType: string) => {
    switch (accessType) {
      case Role.Admin:
        return customerAccessUserType === Role.BusinessAccesCustomer
          ? ParentOnboardingComponent
          : AdministratorComponent;
      case Role.Business:
        return customerAccessUserType === Role.BusinessAccesCustomer
          ? ParentOnboardingComponent
          : CustomerComponent;
      case Role.Customer:
        return ParentOnboardingComponent;
      default:
        return UnderDevelopmentComponent;
    }
  };

  let defaultCustomerName = '';

  if (MemberData) {
    defaultCustomerName = MemberData[0] && MemberData[0]._id;
  } else {
    defaultCustomerName = loginUserDataList[0] && loginUserDataList[0]._id;
  }
  const AccessCustomerName =
    `${loginUserData.firstName} ${loginUserData.lastName ? loginUserData.lastName : ''}` || '';

  if (
    userLocalData.members &&
    !userLocalData.members.length &&
    userLocalData.type === Role.Customer
  ) {
    return (
      <DashboardLayout>
        <Layout style={{ minHeight: '100vh' }}>
          <BrandingHeader />
          <Layout className="site-layout">
            <UnderDevelopmentDiv>
              <Title>Please ask your admin to add member</Title>
              <CustomerAccessBackButton />
            </UnderDevelopmentDiv>
            <Divider />
            <Footer style={{ textAlign: 'center' }}>Copyright ©{COPYRIGHT_YEAR}</Footer>
          </Layout>
        </Layout>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsed={collapse}
          trigger={null}
          defaultCollapsed={false}
          className={`autismag-sidebar${!collapse ? '-show-sidebar' : ''}`}
          data-testid="sider"
        >
          <BlockHeader className="block-header-icon">
            <MenuButton
              style={{
                marginLeft: collapse ? 8 : 0,
                background: 'none',
                paddingLeft: '0px'
              }}
              type="text"
            >
              {collapse ? (
                <img src={hamburgerIcon} className="hamburgerIcon" onClick={handleChange} />
              ) : (
                <div className="desktop-menu">
                  <img src={hamburgerIcon} className="hamburgerIcon" onClick={handleChange} />
                  <Link to={`${match.path}`} className="logo-link">
                    <img src={logoText} className="site-logo" />
                  </Link>
                </div>
              )}
            </MenuButton>
          </BlockHeader>
          <div className="sider-display">
            {userType === Role.Customer || customerAccessUserType === Role.BusinessAccesCustomer ? (
              <Tooltip
                placement="rightTop"
                title={
                  <>
                    <div>
                      <span className="hoveringDetails">Name:</span>
                      <span className="hover-span-style">
                        {' '}
                        {MemberData && MemberData.length
                          ? `${MemberData[0]?.firstName} ${MemberData[0]?.lastName}`
                          : `${MemberData?.firstName} ${MemberData?.lastName}`}
                      </span>
                    </div>
                    <div>
                      <span className="hoveringDetails">Email:</span>
                      <span
                        className="hover-span-style pointer"
                        onClick={async () => {
                          await navigator.clipboard.writeText(loginUserData?.email);
                          message.success('Email Copied!');
                        }}
                      >
                        {' '}
                        {loginUserData?.email} <CopyOutlined />
                      </span>
                    </div>
                    <div>
                      <span className="hoveringDetails">Age:</span>
                      <span className="hover-span-style">
                        {' '}
                        {MemberData && MemberData.length
                          ? MemberData[0]?.dob && memberCalculatedAge()
                          : MemberData?.dob && memberCalculatedAge()}
                      </span>
                    </div>
                    <div>
                      <span className="hoveringDetails">Phone Number:</span>
                      <span className="hover-span-style"> {loginUserData?.phoneNumber}</span>
                    </div>
                    <div>
                      <span className="hoveringDetails">Start Date:</span>
                      <span className="hover-span-style">
                        {' '}
                        {MemberData && MemberData.length
                          ? moment(MemberData[0]?.memberShipStart).isValid()
                            ? `${moment(MemberData[0]?.memberShipStart).format('DD-MM-YYYY')}`
                            : ''
                          : moment(MemberData?.memberShipStart).isValid()
                            ? `${moment(MemberData?.memberShipStart).format('DD-MM-YYYY')}`
                            : ''}
                      </span>
                    </div>
                    <div>
                      <span className="hoveringDetails">Next Rollover Date:</span>
                      <span className="hover-span-style">
                        {' '}
                        {MemberData && MemberData.length
                          ? moment(MemberData[0]?.memberShipEnd).isValid()
                            ? `${moment(MemberData[0]?.memberShipEnd).format('DD-MM-YYYY')}`
                            : ''
                          : moment(MemberData?.memberShipEnd).isValid()
                            ? `${moment(MemberData?.memberShipEnd).format('DD-MM-YYYY')}`
                            : ''}
                      </span>
                    </div>
                    {/* <div><span className="hoveringDetails">Listing Type:</span><span className="hover-span-style">{" "}{MemberData && MemberData.length ? MemberData[0]?.listingType : MemberData?.listingType}</span></div> */}
                    <div>
                      <span className="hoveringDetails">Primary Clinician:</span>
                      <span className="hover-span-style">
                        {' '}
                        {MemberData && MemberData.length
                          ? `${MemberData[0]?.primaryContactObject?.firstName} ${MemberData[0]?.primaryContactObject?.lastName}`
                          : '-'}
                      </span>
                    </div>
                    <div>
                      <span className="hoveringDetails">Chosen Plan:</span>
                      <span className="hover-span-style"> {loginUserData?.choosePlan}</span>
                    </div>
                  </>
                }
              >
                <DropDownSelect className="dropdown-select-mobile-view">
                  {defaultCustomerName ? (
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      suffixIcon={<img src={DownIcon} className="vectorStyle" height="14px" />}
                      size="large"
                      optionLabelProp="label"
                      dropdownClassName="select-user-dropdown"
                      className={collapse ? 'hide-logo' : ''}
                      defaultValue={defaultCustomerName}
                      onChange={handleSelect}
                    >
                      {loginUserDataList &&
                        loginUserDataList.map((optionItem: any, index: number) => (
                          <Option
                            key={index}
                            value={optionItem._id}
                            className="select-user-dropdown-option"
                            label={
                              <>
                                <SelectUserIcon src={UserWhite} className="vectorStyle" />
                                {optionItem.firstName}
                              </>
                            }
                          >
                            {optionItem.firstName}
                          </Option>
                        ))}
                    </Select>
                  ) : null}
                </DropDownSelect>
              </Tooltip>
            ) : null}
            <Menu
              theme="dark"
              mode="inline"
              onClick={handleClickSideBar}
              onOpenChange={handleChangeOpen}
              openKeys={openKeys}
              defaultOpenKeys={subMenuValue}
              inlineCollapsed={false}
              selectedKeys={menuValue}
            >
              {CustomerRouteList.map((sideBarData, index) => {
                const indexKey = index + 1;
                const ParentIcon = sideBarData.icon;
                const { sidebarTitle } = sideBarData;

                const BusinessCustomer = customerAccessUserType === Role.BusinessAccesCustomer;
                return userType === Role.Customer || BusinessCustomer ? (
                  <>
                    {sideBarData.sidebarList && sideBarData.sidebarList.length ? (
                      <SubMenu
                        key={`sub${indexKey}`}
                        icon={<img src={ParentIcon} className="vectorStyle" />}
                        title={sideBarData.sidebarTitle}
                      >
                        {sideBarData.sidebarList.map((sidebarItem, index) =>
                          checkPermission(sidebarItem, index)
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item
                        key={indexKey}
                        icon={
                          <img
                            src={
                              parseInt(menuValue[0]) === indexKey
                                ? sideBarData.activeIcon
                                : ParentIcon
                            }
                            className="vectorStyle"
                          />
                        }
                      >
                        <Link to={`${match.path}${sideBarData.route}`}>
                          {sideBarData.sidebarTitle}
                        </Link>
                      </Menu.Item>
                    )}
                  </>
                ) : null;
              })}

              {BussinessRouteList.map((sideBarData, index) => {
                const indexKey = index + 1;
                const ParentIcon = sideBarData.icon;
                return userType === Role.Business &&
                  customerAccessUserType !== Role.BusinessAccesCustomer ? (
                  <>
                    {sideBarData.sidebarList && sideBarData.sidebarList.length ? (
                      <SubMenu
                        key={`sub${indexKey}`}
                        icon={<img src={ParentIcon} className="vectorStyle" />}
                        title={sideBarData.sidebarTitle}
                      >
                        {sideBarData.sidebarList.map((sidebarItem, index) =>
                          checkPermission(sidebarItem, index)
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item
                        className=""
                        key={indexKey}
                        icon={
                          <img
                            src={
                              parseInt(menuValue[0]) === indexKey
                                ? sideBarData.activeIcon
                                : ParentIcon
                            }
                            className="vectorStyle"
                          />
                        }
                      >
                        <Link to={`${match.path}${sideBarData.route}`}>
                          {sideBarData.sidebarTitle}
                        </Link>
                      </Menu.Item>
                    )}
                  </>
                ) : null;
              })}

              {userType === Role.Admin && customerAccessUserType !== Role.BusinessAccesCustomer ? (
                <>
                  {AdminRouteList.map((sideBarTab, index) => {
                    const indexKey = index + 1;
                    const ParentIcon = sideBarTab.icon;
                    return (
                      <>
                        {sideBarTab.sidebarList && sideBarTab.sidebarList.length ? (
                          <SubMenu
                            //  key={"sub" + sideBarTab.subKey}
                            icon={
                              <img src={ParentIcon} className="vectorStyle ant-menu-item-icon" />
                            }
                            title={sideBarTab.sidebarTitle}
                          >
                            {sideBarTab.sidebarList.map((sidebarItem, index) =>
                              checkPermissionAdmin(sidebarItem, index)
                            )}
                          </SubMenu>
                        ) : (
                          <Menu.Item
                            key={sideBarTab.key}
                            icon={
                              <img
                                src={
                                  menuValue[0] === sideBarTab.key
                                    ? sideBarTab.activeIcon
                                    : ParentIcon
                                }
                                className="vectorStyle ant-menu-item-icon"
                              />
                            }
                          >
                            <Link to={`${match.path}${sideBarTab.route}`}>
                              {sideBarTab.sidebarTitle}
                            </Link>
                          </Menu.Item>
                        )}
                      </>
                    );
                  })}
                </>
              ) : null}

              {customerAccessUserType === Role.BusinessAccesCustomer && !collapse ? (
                <Menu.Item
                  className="withOut-SubMenu Customer-name"
                  style={{ marginLeft: collapse ? 8 : 0 }}
                >
                  {AccessCustomerName ? <span>{AccessCustomerName}</span> : ''}
                </Menu.Item>
              ) : null}
            </Menu>
            {/* <SideMenuSignout>
              <SideMenuSignoutButton onClick={() => onLogoutClick()}>
                <img
                  src={SignOut}
                  className="vectorStyle ant-menu-item-icon signout-image"
                />{" "}
                {collapse ? null : (
                  <span className="signout-text">Sign Out</span>
                )}
              </SideMenuSignoutButton>
            </SideMenuSignout> */}
            {collapse ? null : (
              <SideBarFooter>
                <div className="autismag-div">AUTISM 360™</div>
                <div className="copyright-div">Copyright ©{COPYRIGHT_YEAR}</div>
              </SideBarFooter>
            )}
          </div>
        </Sider>
        <Layout
          className={`site-layout${!collapse ? ' open-sidebar' : ''}`}
          style={{ marginLeft: collapse ? 80 : 200 }}
        >
          <BrandingHeader handleChange={handleChange} />
          <Content className="Main-Dashboard-content-container">
            <Switch>
              <Route exact path={`${match.path}`} component={renderDashBoard(userType)} />
              {routes
                .filter((r) => !(r.roles && !r.roles.includes(userType as Role)))
                .map((r) => (
                  <Route exact path={r.path} component={r.component} />
                ))}
              <Route path={`${match.path}`} component={UnderDevelopmentComponent} />
            </Switch>

            <CustomerAccessBackButton />
          </Content>
          <Divider />
          <Footer style={{ textAlign: 'center' }}>Copyright ©{COPYRIGHT_YEAR}</Footer>
        </Layout>
        <WelcomeModal />
        {/* <AddGoalComponent onCloseDrawer={onCloseDrawer} user={Role.Customer} formTypeLabel={"Goal"} addButtonLabel="View Goal" chooseForm="View" id={userrInfo._id} propVisible={visible}/> */}
      </Layout>
    </DashboardLayout>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    customerInfo: state.customerRuducer.customerInfo,
    selectCustomer: state.customerRuducer.selectCustomer,
    MemberData: state.customerRuducer.customerSelectedMember,
    loginUserData: state.userMemberData.loginUserData,
    currentLoginUser: state.userMemberData.currentLoginUser,
    customerAccessData: state.customerRuducer.customerAccessData,
    userLocalData: state.customerRuducer.userLocalData,
    customerLoading: state.customerRuducer.customerLoading,
    herderLabel: state.trackReducer.herderLabel,
    customerFormDetails: state.customerRuducer.customerFormDetails,
    openWelcomeModal: state.customerRuducer.openWelcomeModal,
    visible: state.notificationReducer.visible,
    customerDataList: state.notificationReducer.customerDataList,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(Dashboard);
